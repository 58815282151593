@import '@/theme/vars';
@import '../index.scss';

.landing-rewards-center-container {
  background-color: #0c0c0e;
  position: relative;
  .landing-container {
    overflow: hidden;
  }

  .text-wrapper {
    text-align: center;
    margin-top: 60px;

    .title-rewards {
      @include title;
    }

    .title-center {
      @include title;
    }

    .description {
      font-size: 16px;
      line-height: 150%;
      color: $TEXT_COLOR_GRAY;
    }
  }

  .content-wrapper {
    margin-top: 45px;
    margin-bottom: 85px;
  }

  .highlight-wrapper {
    padding-top: 70px;
    height: 136px;
    display: flex;
    align-items: flex-start;

    .right-arrow-icon {
      color: $PRIMARY;
      font-size: 45px;
      width: 45px;
      height: 45px;
    }

    .vertical-line {
      width: 3px;
      height: 82px;
      background-color: $PRIMARY;
      margin: 0;
    }

    .space-3-space-item {
      &:last-child {
        flex-grow: 1;
      }

      .highlight-text-container {
        height: 82px;
        background: linear-gradient(
          90deg,
          rgba(11, 203, 193, 0.2) 0%,
          rgba(11, 203, 193, 0) 100%
        );
        display: flex;
        align-items: center;
        padding: 16px;

        .text {
          margin-left: 30px;
          font-size: 36px;
        }
      }
    }
  }

  .discount {
    margin-left: 30px;
    background-color: transparent;
    .space-3-card-body {
      padding: 12px;
      padding-right: 40px;
      border: 0px;
      border-radius: 18px;
      background-color: adjust-color($color: $WARNING, $alpha: -0.86);
      display: inline-block;

      .num {
        line-height: 64px;
        color: $WARNING;
        font-size: 40px;
        font-weight: 500;
        position: relative;

        &::after {
          content: '%';
          font-weight: 300;
          font-size: 24px;

          position: absolute;
          bottom: -5px;
        }
      }
    }
  }

  .icon-token {
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  .discount-price {
    font-size: 24px;
    font-weight: 500;
  }
  .original-price {
    font-size: 14px;
    text-decoration: line-through;
  }
  .or-text {
    color: $TEXT_COLOR_GRAY;
    font-size: 16px;
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .text-wrapper {
      margin-top: 32px;
    }
    .content-wrapper {
      margin-bottom: 0px;
    }
    .highlight-wrapper {
      padding-top: 0px;
      margin-bottom: 31px;
      .right-arrow-icon {
        font-size: 32px;
        width: 32px;
        height: 32px;
      }

      .vertical-line {
        height: 51px;
      }

      .space-3-space-item {
        .highlight-text-container {
          height: 51px;
          background: linear-gradient(
            90deg,
            rgba(11, 203, 193, 0.2) 0%,
            rgba(11, 203, 193, 0) 100%
          );
          display: flex;
          align-items: center;
          padding: 10px;

          .text {
            font-size: 24px;
          }
        }
      }
    }
  }
}
