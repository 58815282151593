@import '@/theme/vars';

@mixin title {
  font-size: 64px;
  line-height: 64px;
  margin-bottom: 0px;
  font-weight: 400;

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    font-size: 32px;
    line-height: 32px;
  }
}

@mixin text-button {
  font-size: 18px;
  @media screen and (max-width: 750px) {
    font-size: 16px;
  }
}
