@import '../index.scss';

.landing-communities {
  padding: 80px 0;
  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 40px 0;
  }
  .title {
    text-align: center;
    @include title;

    .title-space3 {
      display: inline-block;
      @include title;
    }
  }
  .img-wrapper {
    width: 100%;
    position: relative;
    .img {
      object-fit: contain;
    }
  }

  .btn-wrapper {
    display: flex;
    .btn {
      padding: 24px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include text-button;
    }
  }
}
