@import '@/theme/vars';
@import '../index.scss';

@mixin font-size {
  font-size: 32px;

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    font-size: 24px;
  }
}
.landing-metric-section {
  background-color: #111214;
  padding: 48px 0;

  .inner-container {
    width: 1140px;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 30px);
    }

    .txt {
      line-height: 40px;
      @include font-size;
    }

    .txt-desc {
      font-size: 18px;
      font-weight: 200;
      color: $SECONDARY;
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 40px 0;

    .txt-desc {
      font-size: 14px;
    }
  }
}
