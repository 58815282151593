@import '@/theme/vars';
@import '../index.scss';

.landing-missions-system {
  overflow: hidden;
  .landing-inner-container {
    padding: 62px 0px;
    padding-bottom: 88px;
    &::before {
      background-image: url('/images/landing-community-ep-moon.png');
      background-repeat: no-repeat;
      background-size: 93%;
      opacity: 50%;
      top: 300px;
      left: 50%;
      transform: translateX(calc(-50% - 585px));
      width: 788px;
      height: 788px;
      position: absolute;
      content: '';
    }

    .card-wrapper {
      width: 544px;
      height: 468px;
      max-height: 468px;
      aspect-ratio: 544/468;
      position: relative;
      border: 0px;
      background-color: #ffffff00;
      .space-3-image {
        position: absolute;
        inset: 0;
        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .title-wrapper {
      margin-bottom: 70px;
      display: flex;
      .text-mission {
        @include title;
        margin-right: -150px;
        .text-system {
          color: white;
        }

        @media screen and (max-width: $MAX_WIDTH_MOBILE) {
          text-align: center;
        }
      }

      .desc {
        color: $TEXT_COLOR_GRAY;
        font-size: 16px;

        @media screen and (max-width: $MAX_WIDTH_MOBILE) {
          text-align: center;
        }
      }
    }

    .item {
      border: 0px;
      border-bottom: 0.5px #ffffff4d solid;
      border-top: 0.5px #ffffff00 solid;
      border-radius: 0px;
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: #ffffff00;

      &:hover {
        background: linear-gradient(
          90deg,
          rgba(11, 203, 193, 0) 0%,
          rgba(11, 203, 193, 0.14) 48.55%,
          rgba(11, 203, 193, 0) 100%
        );
        border-bottom: 0.5px white solid;
        border-top: 0.5px white solid;
        .no {
          color: #ebebeb;
        }
        .content {
          color: #0bcbc1;
        }
      }

      .no {
        color: $TEXT_COLOR_GRAY;
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .content {
        color: #ebebeb;
        font-size: 24px;
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: $MAX_WIDTH_MOBILE) {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: $MAX_WIDTH_MOBILE) {
      padding: 47px 0px;
      .card-wrapper {
        width: 100%;
        height: 100%;
      }
      .title-wrapper {
        margin-bottom: 0px;
        .text-mission {
          text-align: center;
        }
        .desc {
          text-align: center;
        }
      }
      .item {
        .content {
          font-size: 16px;
        }
      }
    }
  }
}
