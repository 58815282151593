@import '@/theme/vars';

.landing-hero-banner-card {
  margin-top: -50px;

  .card-wrapper {
    aspect-ratio: 136/117;
    position: relative;
    display: flex;
    max-width: 597px;
    margin-left: 140px;
    width: 100%;
    .card {
      object-fit: contain;
      z-index: 1;
    }

    .space-3-image {
      position: absolute;
      inset: 0;
    }
    .mesh {
      width: 100%;
      height: 100%;
      // top: 70px !important;
    }
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 140px;

    .text-page-wrapper {
      display: flex;
      .current-page {
        font-size: 24px;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
      .total-page {
        font-size: 18px;
        color: #535353;
        letter-spacing: 0.2px;
        line-height: 18px;
      }
    }

    .icon {
      width: 45px;
      height: 45px;
      font-size: 40px;
      color: $PRIMARY;
      border: none;
      background: transparent;
      box-shadow: none;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-navigate-swiper--md {
      color: #373737;
      &:hover {
        color: $PRIMARY;
      }
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    margin-left: 0px;
    .card-wrapper {
      margin-left: 0px;
    }
    .pagination-wrapper {
      margin-left: 0px;
    }
  }
}
