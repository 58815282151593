@import '@/theme/vars';
@import '../index.scss';

.landing-community-ep-text {
  .title {
    display: inline-block;
    @include title;
    text-align: center;
  }
  .title-color {
    display: inline-block;
    @include title;
    text-align: center;
  }
  .desc {
    text-align: center;
    color: $TEXT_COLOR_GRAY;
    font-size: 16px;
    margin: auto;
    display: block;
  }
}
