@import '@/theme/vars';

.landing-container {
  .landing-inner-container {
    max-width: 1140px;

    @media screen and (max-width: 1200px) {
      max-width: calc(100% - 30px);
    }
  }
}
