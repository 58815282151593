@import '../index.scss';
@import '@/theme/vars';

.landing-how-it-work {
  padding: 48px 0px;

  .inner-container {
    width: 1140px;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 30px);
    }
  }
  .title-wrapper {
    display: flex;

    .title {
      @include title;
      text-align: center;

      .title-work {
        @include title;
        display: inline-block;
      }
    }
    .desc {
      color: $TEXT_COLOR_GRAY;
      margin-bottom: 0px;
      text-align: center;
    }
  }

  .item-wrapper {
    .card {
      width: 180px;
      height: 210px;

      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;
      background-color: #0d0f11;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      .space-3-image {
        position: absolute;
        inset: 0;
      }
      .number {
        position: absolute;
        top: 8px;
        right: 8px;

        background-color: #ffffff00;
        border-radius: 71px;
        border: 0.5px solid #0fdbd1;
      }

      .border {
        position: absolute;
        border: 1px solid #4d5a66;
        inset: 1px;
        border-radius: 16px;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .border {
          border: 1px solid $PRIMARY;
          box-shadow: 0px 4px 30px 0px rgba(9, 188, 198, 0.24);
        }
      }
    }

    .desc {
      text-align: center;
      color: #ebebeb;
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 42px 0px;
    .inner-container {
      width: calc(100% - 30px);
    }
  }

  @media screen and (max-width: 410px) {
    .inner-container {
      .item-wrapper {
        .card {
          transform: scale(0.8);
        }
      }
    }
  }
}
