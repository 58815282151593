@import '@/theme/vars';

.landing-community-ep-container {
  background-color: $BG_PRIMARY;
  overflow: hidden;

  .landing-container {
    overflow: visible;

    &::after {
      background-image: url('/images/landing-community-ep-moon.png');
      background-repeat: no-repeat;
      background-size: 93%;
      opacity: 50%;
      top: 650px;
      right: 50%;
      transform: translateX(calc(50% + 500px));
      width: 788px;
      height: 788px;
      position: absolute;
      content: '';
    }
  }
  .landing-inner-container {
    overflow: visible;
    position: relative;

    &::before {
      background-image: url('/images/landing-community-ep-universal.png');
      background-repeat: no-repeat;
      background-size: 93%;
      opacity: 50%;

      top: 150px;
      left: 50%;
      transform: translateX(calc(-50% - 585px));
      width: 788px;
      height: 788px;
      position: absolute;
      content: '';
    }

    .content-wrapper {
      padding-top: 43px;
      padding-bottom: 142px;
      position: relative;
      z-index: 1;

      @media screen and (max-width: $MAX_WIDTH_MOBILE) {
        padding-top: 42px;
        padding-bottom: 42px;
      }
    }
  }
}
