@import '@/theme/vars';

.landing-hero-section {
  display: flex;
  justify-content: center;
  overflow: hidden;

  background-image: url('/images/landing-hero-banner-earth.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .inner-container {
    padding: 0px;
    padding-bottom: 80px;
    width: 1140px;

    .hero-banner-heading {
      display: flex;
      align-items: center;

      .title {
        font-size: 60px;
        font-weight: 500;
      }

      .btn-wrapper {
        display: flex;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 50px;
          padding-top: 16px;
          padding-bottom: 16px;
          text-align: center;
          font-size: 18px;

          // &:first-child {
          //   margin-right: 20px;
          // }
        }
      }
    }

    .card-wrapper {
      .inner-card-wrapper {
        .img-wrapper {
          border: none;
          margin-top: 34px;
          // margin-bottom: 20px;
          display: block;
          position: relative;
          flex-shrink: 0;
          flex-grow: 0;
          width: 100%;
          aspect-ratio: 544/468;
          max-width: 544px;

          .space-3-image {
            position: initial;
            .img {
              object-fit: contain;
              z-index: 1;
              position: absolute;
              inset: 0;
            }

            .mesh {
              object-fit: contain;
              position: absolute;
              inset: 0;
            }
          }

          @media screen and (max-width: $MAX_WIDTH_MOBILE) {
            margin-top: 0px;
            margin-bottom: 34px;
          }
        }
      }
    }
    &--wrapper {
      gap: 50px;
    }
  }

  @media screen and (max-width: 1200px) {
    .inner-container {
      width: calc(100% - 30px);
      background-size: 50%;
      background-position-y: 580px;
      background-position-x: -30px;
    }
  }
  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .inner-container {
      .hero-banner-heading {
        order: 2;
        justify-content: center;
        .title {
          font-size: 32px;
          text-align: center;
          justify-content: center;
        }
        .btn-wrapper {
          justify-content: center;
          .btn {
            font-size: 16px;
          }
        }
        &--wrapper {
          gap: 32px !important;
        }
      }
      .card-wrapper {
        order: 1;
        margin-top: 34px;
      }
    }
  }
}
