@import '@/theme/vars';

.landing-community-ep-list-wrapper {
  display: flex;
  .list-item {
    padding: 16px;
    background-color: #0d0f11;
    border-radius: 16px;

    &:hover {
      .text {
        color: $WARNING;
      }
    }
    .text {
      font-size: 16px;
      color: $TEXT_COLOR_SECONDARY;
      transition: all 0.3s ease-in-out;
    }
  }
}
